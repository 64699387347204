<template>
  <div class="card card-custom gutter-b">
    <div class="card-header d-flex justify-content-between">
      <div class="card-title">
        <h3 class="card-label">Danh sách nhân viên</h3>
      </div>
      <div class="card-title">
        <div class="dropdown dropdown-inline mr-2">
          <el-button type="primary" class="btn font-weight-bolder text-white mr-2"
                     style="background: #5814e2; outline: none" @click="isImport = true">Import KRA
          </el-button>
          <button type="button" @click="exportCustomers" :disabled="is_disable_export"
                  class="btn btn-light-primary font-weight-bolder"
                  :class="is_disable_export ? 'spinner spinner-white spinner-left' : ''">
            <span class="svg-icon svg-icon-md">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                      d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                      fill="#000000" opacity="0.3">
                  </path>
                  <path
                      d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                      fill="#000000"></path>
                </g>
              </svg>
            </span>
            Xuất excel
          </button>
        </div>
        <!--        <div class="dropdown dropdown-inline mr-2">-->
        <!--          <button type="button" @click="exportCustomersV2" :disabled="is_disable_export"-->
        <!--                  class="btn btn-light-primary font-weight-bolder"-->
        <!--                  :class="is_disable_export ? 'spinner spinner-white spinner-left' : ''">-->
        <!--            <span class="svg-icon svg-icon-md">-->
        <!--              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">-->
        <!--                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
        <!--                  <rect x="0" y="0" width="24" height="24"></rect>-->
        <!--                  <path d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"-->
        <!--                        fill="#000000" opacity="0.3">-->
        <!--                  </path>-->
        <!--                  <path d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"-->
        <!--                        fill="#000000"></path>-->
        <!--                </g>-->
        <!--              </svg>-->
        <!--            </span>-->
        <!--            Xuất excel v2-->
        <!--          </button>-->
        <!--        </div>-->
        <div class="dropdown dropdown-inline mr-2">
          <button
              type="button" @click="exportCustomerExample" :disabled="is_disable_export_example"
              class="btn btn-info font-weight-bolder"
              :class="is_disable_export_example ? 'spinner spinner-white spinner-left' : ''">
									<span class="svg-icon svg-icon-md">
											<svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" stroke-width="1" fill="none"
                               fill-rule="evenodd">
															<rect x="0" y="0" width="24" height="24"></rect>
															<path
                                  d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                                  fill="#000000" opacity="0.3"></path>
															<path
                                  d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                                  fill="#000000"></path>
                            </g>
											</svg>
                  </span>Dowload file mẫu
          </button>
        </div>
        <router-link
            v-if="currentUser.user.permission.includes(permission.THEM_NHAN_SU_CO_SO)"
            class="font-weight-bold font-size-3  btn btn-success"
            :to="{ name: 'user-create' }"
        >Thêm mới
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <!--begin::Example-->
      <div class="example mb-10">
        <div style="display: flex; justify-content: end; width: 100%; padding-right: 7px">
          <div class="example-btn d-flex justify-content-end">
            <div v-for="file in files" :key="file.id">
              <i class="fas fa-minus-circle mr-2" style="cursor: pointer;"
                 @click="removeFile"></i>
              <span>{{ file.name }}</span> -
              <span>{{ file.size }}</span> -
              <span v-if="file.error">{{ file.error }}</span>
              <span v-else-if="file.success">success</span>
              <span v-else-if="file.active">active</span>
              <span v-else-if="!!file.error">{{ file.error }}</span>
              <span v-else></span>
            </div>
            <file-upload
                class="btn btn-text-dark-50 btn-icon-primary font-weight-bold btn-hover-bg-light mr-3"
                post-action=""
                extensions="xlsx,xls"
                accept=".xlsx,.xls"
                :size="1024 * 1024 * 10"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload">
              <i class="flaticon-upload"></i>
              Chọn file
            </file-upload>
            <button type="button" @click="onSubmit" class="btn btn-primary mr-3"
                    :class="{'spinner spinner-darker-success spinner-left' : is_loading_import}"
            >
              Upload file
            </button>
          </div>

        </div>
        <div class="mt-4  row filter_form">
          <div class="form-group col-md-3">
            <div class="input-group">
              <div class="input-group-prepend">
																<span class="input-group-text line-height-0 py-0">
																	<span class="svg-icon">
																		<svg xmlns="http://www.w3.org/2000/svg"
                                         width="24px" height="24px"
                                         viewBox="0 0 24 24" version="1.1">
																			<g stroke="none" stroke-width="1"
                                         fill="none" fill-rule="evenodd">
																				<rect x="0" y="0" width="24"
                                              height="24"></rect>
																				<path
                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                            fill="#000000"
                                            fill-rule="nonzero"
                                            opacity="0.3"></path>
																				<path
                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                            fill="#000000"
                                            fill-rule="nonzero"></path>
																			</g>
																		</svg>
																	</span>
																</span>
              </div>
              <input type="text" class="form-control" placeholder="Tìm kiếm" v-model="query.keyword"
                     @input="search"
                     aria-describedby="basic-addon2">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <el-select v-model="query.area_id" clearable filterable class="w-100"
                         placeholder="Chọn khu vực" @change="getBranches">
                <el-option
                    v-for="(item, key) in areas"
                    :label="item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <el-select v-model="query.branch_id" clearable filterable class="w-100"
                         placeholder="Chọn văn phòng">
                <el-option
                    v-for="(item, key) in headquarters"
                    :label="item.name_alias ? item.name_alias : item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <el-select v-model="query.account_type_id"
                         clearable
                         filterable
                         class="w-100"
                         placeholder="Chọn chức vụ"
                         @input="handleAccountType"
              >
                <el-option
                    v-for="(item, key) in accountType"
                    :label="item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3" v-if="showBusinessLevel">
            <div class="form-group">
              <el-select filterable
                         class="w-100"
                         placeholder="Cấp bậc"
                         v-model="query.business_level_id"
                         clearable
              >
                <el-option
                    v-for="item in businessLevels"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <el-select v-model="query.positions" clearable multiple filterable class="w-100"
                         placeholder="Chọn kiêm nhiệm">
                <el-option
                    v-for="(item, key) in positions"
                    :label="item.position"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.position }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <el-select v-model="query.status" clearable filterable class="w-100"
                         placeholder="Chọn trạng thái">
                <el-option
                    v-for="(item, key) in status_search"
                    :label="item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <el-select v-model="query.department_id" clearable filterable class="w-100"
                         placeholder="Chọn phòng ban">
                <el-option
                    v-for="(item, key) in departments"
                    :label="item.name"
                    :value="item.id"
                    :key="key"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <button class="btn btn-primary font-weight-bold mr-2"
                        :class="is_disable_search ? 'spinner spinner-white spinner-left' : ''"
                        @click="searchData()">
                  <i class="fa fa-search" v-if="!is_disable_search"></i> Tìm kiếm
                </button>
              </div>
            </div>
          </div>
        </div>
        <DoubleScrollbar class="example-preview table-responsive overflow-auto">
          <table class="table table-vertical-center">
            <thead>
            <tr>
              <td>STT</td>
              <th scope="col">Họ tên</th>
              <th scope="col">Mã nhân viên</th>
              <th scope="col">SĐT</th>
              <th scope="col">Email</th>
              <!--              <th scope="col">Phòng ban</th>-->
              <th scope="col" class="min-w-150px">Chức vụ</th>
              <th scope="col" class="min-w-100px">Cấp bậc</th>
              <th scope="col">Kiêm nhiệm</th>
              <th scope="col" class="min-w-150px">Mã cấp trên</th>
              <th scope="col" class="min-w-150px">Văn phòng</th>
              <th scope="col" class="min-w-150px">Khu vực</th>
              <th scope="col" width="8%">Trạng thái</th>
              <th scope="col" class="min-w-150px" width="8%">Ngày bắt đầu</th>
              <th scope="col" class="min-w-150px" width="8%">Ngày nghỉ việc</th>
              <th scope="col" width="8%">Role</th>
              <th scope="col" width="8%">Xác minh</th>
              <th scope="col" class="min-w-200px">Hành động</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in users.data" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ parseInt(item.id) + 100000 }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <!--              <td></td>-->
              <td>{{
                  item.profile != null && item.profile.account_type != null ? item.profile.account_type.name : ''
                }}
              </td>
              <td>{{ item.profile.businesslevel ? item.profile.businesslevel.name : '' }}</td>
              <td v-if="item.user_positions !== undefined && item.user_positions.length"><span
                  v-for="(i , k) in item.user_positions"
                  :key="k"><span
                  class="badge badge-pill badge-success mt-1">{{ i.position }}</span></span>
              </td>
              <td v-else></td>
              <td>{{ item.parent_id ? parseInt(item.parent_id) + 100000 : '' }}</td>
              <td>{{ getChiNhanh(item) }}</td>
              <td>{{ renderArea(item) }}
              </td>
              <td>
                <span class="badge" v-bind:class="item.status === 1 ? classSpan.success : classSpan.danger">
                  {{ item.status === 1 ? "Hoạt động" : 'Nghỉ việc' }}
                </span>
              </td>
              <td>
                {{ item.profile && item.profile.start_to_work && convertTimeEnd(item.profile.start_to_work) }}
              </td>
              <td>
                {{
                  item.profile && item.profile.thoi_gian_nghi_viec && convertTimeEnd(item.profile.thoi_gian_nghi_viec)
                }}
              </td>
              <td>
                {{ item?.backend_role_name }}
              </td>
              <td>
                <a href="javascript:" class="badge"
                   v-bind:class="item.profile.status_verify === 2 ? classSpan.primary : classSpan.secondary"
                   :style="item.profile.status_verify === 2 ? 'cursor: pointer;' : 'cursor: auto;'"
                   @click="indentityVerify(item)
                                   "
                >
                  {{ item.profile && item.profile.status_verify === '2' ? 'Đã xác minh' : 'Chưa xác minh' }}
                </a>
              </td>
              <td>
                <a v-if="item.status === 1" class="btn btn-icon mr-2 btn-outline-warning"
                   @click="showModalUserActive(item)" href="javascript:">
                  <i class="fas fa-user-times"></i>
                </a>
                <a v-else class="btn btn-icon mr-2 btn-outline-primary" @click="showModalUserDeActive(item)"
                   href="javascript:">
                  <i class="fas fa-user-plus"></i>
                </a>
                <router-link :to="{ name: 'user-edit', params: {id : item.id} }" title="Sửa"
                             v-if="currentUser.user.permission.includes(permission.SUA_NHAN_SU_CO_SO)"
                             class="btn btn-icon mr-2 btn-outline-info"><i class="fas fa-pen-nib"></i>
                </router-link>
                <a title="Xóa" @click="deleteUser(item.id)" href="javascript:"
                   v-if="currentUser.user.permission.includes(permission.XOA_NHAN_SU_CO_SO)"
                   class="btn btn-icon btn-outline-danger"><i class="fas fa-trash"></i></a>

              </td>
            </tr>
            </tbody>
          </table>
        </DoubleScrollbar>
        <DetailImageIdentity :showModal="showModal" @clicked="onclickViewChange"
                             :userIdentity="userIdentity"></DetailImageIdentity>

        <b-modal id="bv-modal-example" title="BootstrapVue">
          <p class="my-4">Hello from modal!</p>
        </b-modal>
      </div>
    </div>
    <div class="edu-paginate mx-auto text-center">
      <paginate
          v-model="page"
          :page-count="last_page"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'Trước'"
          :next-text="'Sau'"
          :container-class="'pagination b-pagination'"
          :pageLinkClass="'page-link'"
          :page-class="'page-item'"
          :next-link-class="'next-link-item'"
          :prev-link-class="'prev-link-item'"
          :prev-class="'page-link'"
          :next-class="'page-link'"
      >
      </paginate>
    </div>

    <!--   Modal nhân sự nghỉ việc   -->
    <b-modal :title="dialog.title" class="dialog-active" @hide="onHide" hide-footer :visible.sync="showUserActive">
      <el-row :gutter="10" class="pr-2 pl-2">
        <h5>Bạn có muốn cho nhân sự: <b>{{ user_active.name }}</b></h5>
        <h5>Mã nhân sự: <b>{{ user_active.id + 100000 }}</b></h5>
        <div class="form-row">
          <div class="col-md-12 mt-3">
            <h5 class="mb-3">Thôi việc từ ngày</h5>
            <ValidationProvider vid="date-end" name="Ngày nghỉ việc" rules="required"
                                v-slot="{ errors,classes }">
              <el-date-picker
                  :class="`${classes} w-100`"
                  v-model="dialog.endDateLeave"
                  type="date"
                  ref="picker"
                  :onPick="pickEnd"
                  format="yyyy-MM-dd"
                  @change="pickEnd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerEndLeaveOptions"
                  placeholder="yyyy-MM-dd">
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="date-end" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>

          </div>
          <div class="col-md-12 mt-3">
            <input v-model="dialog.reason_lay_off" disabled type="text" class="form-control" id="address"
                   placeholder="Nhập địa chỉ thường trú">
          </div>
        </div>
        <button :disabled="loading.save || !dialog.endDateLeave " @click="dismissalOrActiveQuery(user_active.id)"
                class="swal2-confirm swal2-styled float-right mt-5" type="submit"
                :style="`${ (loading.save || !dialog.endDateLeave) ? 'opacity:0.65' : ''}`">
          <i v-if="loading.save" class="el-icon-loading"></i>
          Đồng ý cho nghỉ
        </button>
        <button class="swal2-cancel swal2-styled float-right mt-5 mr-3" @click="showUserActive = false"> Hủy</button>
      </el-row>
    </b-modal>
    <el-dialog
        :title="titleDialogImportKRA"
        :visible.sync="isImport"
        width="50%"
        :before-close="handleClose"
        :show-close="false"
        top="8vh"
    >
      <hr style="margin-top: -20px"/>
      <DialogImportKRA @setTitleError="setTitle" @closeDialog="isImport = false"/>
    </el-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {GET_ACCOUNT_TYPE, GET_POSITIONS} from "@/core/services/store/user/account-type.module";
import Swal from "sweetalert2";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  ACTIVE_USER,
  DELETE_USER,
  DISMISS_USER,
  EXPORT_CUSTOMERS, EXPORT_CUSTOMERS_V2, EXPORT_EMPLOYEE_EXAMPLE, EXPORT_STAFF,
  GET_USERS, IMPORT_EMPLOYEE,
} from "@/core/services/store/user/users.module";
import Multiselect from 'vue-multiselect'
import FileUpload from 'vue-upload-component';
import DetailImageIdentity from './DetailImageIdentity';
import DoubleScrollbar from '@/view/content/DoubleScrollbar';
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {GET_AREAS} from "@/core/services/store/center/area.module";
import moment from 'moment-timezone';
import {GET_BUSINESS_LEVEL} from "@/core/services/store/business/level.module";
import {GET_DEPARTMENT} from "@/core/services/store/user/department.module";
import {GDKV, GDVP, SALE_LEADER, SALE_MEMBER} from "@/core/option/accountTypeOption";
import {exportExcel} from "@/core/filters";
import DialogImportKRA from "@/view/pages/user/Component/DialogImportKRA.vue";

const _ = require('lodash');

export default {
  components: {
    DialogImportKRA,
    Multiselect, FileUpload, DoubleScrollbar, DetailImageIdentity
  },
  name: "UserIndex",
  data() {
    return {
      query: {
        keyword: '',
        positions: '',
        status: '',
        branch_id: '',
        area_id: '',
        account_type_id: '',
        business_level_id: '',
        department_id: ''
      },
      isImport: false,
      users: [],
      keyword: '',
      // Paginate
      page: 1,
      last_page: 1,
      accountType: [],
      status: {
        0: 'Không hoạt động',
        1: 'Hoạt động',
        2: "Nghỉ việc"
      },
      headquarters: [],
      areas: [],
      status_search: [
        {
          id: 1,
          name: 'Hoạt động'
        }, {
          id: 0,
          name: 'Nghỉ việc'
        },

      ],
      classSpan: {
        success: 'badge-success',
        danger: 'badge-danger',
        primary: 'badge-primary',
        secondary: 'badge-secondary'
      },
      positions: [],
      // Cache
      cachePosition: [],
      is_disable_search: false,
      is_disable_export: false,
      is_disable_export_example: false,
      showModal: false,
      userIdentity: {},
      user_active: {},
      showUserActive: false,
      dialog: {
        title: "",
        endDateLeave: null,
        reason_lay_off: null,
      },
      pickerEndLeaveOptions: {
        disabledDate: this.disabledLeaveEndDate
      },
      loading: {
        save: false,
      },
      businessLevels: [],
      showBusinessLevel: false,
      files: [],
      is_loading_import: false,
      departments: [],
      titleDialogImportKRA: 'Import KRA'
    }
  },
  watch: {
    positions() {
      if (this.query.position !== undefined) {
        let filterCachePosition = this.positions.filter((value) => {
          if (this.query.position === value.id) {
            return value
          }
        })
        this.cachePosition = filterCachePosition
      }
    }
  },
  created() {
    this.getBranches();
    this.getAreas();
    this.getPositions();
    this.getAccountType();
    this.getAllDepartment();
    this.getList();
  },
  mounted() {
    this.checkShowBusinessLevel();
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Quản lý nhân sự", route: 'profile-1'},
        {title: "Danh sách nhân viên"}
      ]);
    },
    handleClose() {
      this.isImport = false
    },
    handleAccountType(id) {
      this.businessLevels = [];
      this.query.business_level_id = '';
      this.checkShowBusinessLevel(id);
    },
    checkShowBusinessLevel() {
      let id = this.query.account_type_id;
      this.showBusinessLevel = false;
      if ([GDVP, GDKV, SALE_LEADER, SALE_MEMBER].includes(id)) {
        this.getBusinessLevels();
        this.showBusinessLevel = true;
      }
    },
    getAllDepartment() {
      this.$store.dispatch(GET_DEPARTMENT, {
        all: true
      }).then((data) => {
        this.departments = data.data;
      })
    },
    getBusinessLevels() {
      this.$store.dispatch(GET_BUSINESS_LEVEL, {
        limit: 10000,
        status: 1,
        account_type_id: this.query.account_type_id
      }).then((res) => {
        if (!res.error) {
          this.businessLevels = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    onHide() {
      this.showUserActive = false;
    },
    setTitle(newTitle) {
      this.titleDialogImportKRA = newTitle
    },
    convertTimeEnd(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    disabledLeaveEndDate(date) {
      this.fromDateLeave = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      this.fromDateStart = new Date(moment(this.user_active.profile.start_to_work).format('YYYY'),
          moment(this.user_active.profile.start_to_work).format('MM') - 1,
          moment(this.user_active.profile.start_to_work).format('DD'));
      if (this.fromDateLeave || this.fromDateStart) {
        return this.fromDateLeave > date || date > new Date() || this.fromDateStart > date;
      }
      return date > new Date();
    },
    pickEnd(date) {
      this.toDate = null;
      if (date) {
        this.toDate = new Date(date);
      }
    },
    showModalUserActive(user_active) {
      this.showUserActive = true;
      this.dialog.endDateLeave = moment().format('YYYY-MM-DD');
      this.user_active = user_active
      this.dialog.title = "Thông báo";
      this.dialog.reason_lay_off = `OUT ${this.user_active.profile.account_type.name}`
    },
    showModalUserDeActive(user) {
      Swal.fire({
        title: "Bạn chắc chắn muốn kích hoạt " + user.name + " ?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            id: user.id,
            params: ''
          }
          this.$store.dispatch(ACTIVE_USER, payload);
          Swal.fire("Thành công", "", "success");
        }
      });
    },
    dismissalOrActiveQuery(id) {
      let payload = {
        id: id,
        params: {
          date: this.dialog.endDateLeave,
          reason: ""
        }
      }
      this.loading.save = true;
      this.$store.dispatch(DISMISS_USER, payload).then(() => {
        this.showUserActive = false
        Swal.fire("Thành công", "", "success");
      }).catch((error) => {
        this.$notify({
          title: 'Thất bại',
          message: error.data.message,
          type: 'error'
        });
      }).finally(() => {
        this.loading.save = false;
      });
    },
    onclickViewChange() {
      this.showModal = false;
    },
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data.filter(
              item => item.id !== 9
                  && item.id !== 8
                  && item.id !== 7
                  && item.id !== 11
                  && item.id !== 10
                  && item.id !== 13
          );
        }
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES, {
        limit: 10000,
        center_id: this.query.center_id,
        area_id: this.query.area_id
      }).then((res) => {
        if (!res.error) {
          this.headquarters = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getAreas() {
      this.$store.dispatch(GET_AREAS, {
        limit: 10000,
        status: 1
      }).then((res) => {
        if (!res.error) {
          this.areas = res.data.data;
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    getList() {
      this.page = parseInt(this.$route.query.page)
      this.pullParamsUrl();
      this.callApiGetList();
    },
    pushParamsUrl() {
      this.$router.push({
        name: 'user-index',
        query: {
          keyword: this.query.keyword,
          branch_id: this.query.branch_id,
          position: this.query.positions,
          status: this.query.status,
          account_type_id: this.query.account_type_id,
          area_id: this.query.area_id,
          business_level_id: this.query.business_level_id,
          department_id: this.query.department_id,
          page: this.query.page
        }
      })
    },
    pullParamsUrl() {
      this.query.keyword = this.$route.query.keyword;
      this.query.branch_id = this.$route.query.branch_id ? parseInt(this.$route.query.branch_id) : '';
      this.query.position = this.$route.query.position;
      this.query.status = this.$route.query.status ? parseInt(this.$route.query.status) : '';
      this.query.account_type_id = this.$route.query.account_type_id ? parseInt(this.$route.query.account_type_id) : '';
      this.query.page = this.$route.query.page;
      this.query.area_id = this.$route.query.area_id ? parseInt(this.$route.query.area_id) : '';
      this.query.business_level_id = this.$route.query.business_level_id ? parseInt(this.$route.query.business_level_id) : '';
      this.query.department_id = this.$route.query.department_id ? parseInt(this.$route.query.department_id) : '';
    },
    search: _.debounce(function () {
      this.query.branch_id = this.$route.query.branch_id
      this.query.position = this.$route.query.position
      this.query.status = this.$route.query.status
      this.pushParamsUrl()
      this.page = 1
      this.callApiGetList()
    }, 200),
    clickCallback(obj) {
      this.page = obj
      this.$router.push({path: 'employees', query: {...this.query, page: this.page}})
      this.callApiGetList()
    },
    searchData() {
      this.pushParamsUrl();
      this.callApiGetList();
    },
    callApiGetList() {
      this.is_disable_search = true;
      this.$store.dispatch(GET_USERS, {
        keyword: this.query.keyword,
        page: this.page,
        branch_id: this.query.branch_id,
        area_id: this.query.area_id,
        position: this.query.positions,
        status: this.query.status,
        account_type_id: this.query.account_type_id,
        business_level_id: this.query.business_level_id,
        department_id: this.query.department_id,
        is_staff: 1,
      }).then((res) => {
        this.page = res.data.current_page
        this.users = res.data
        this.last_page = res.data.last_page;
      }).finally(() => {
        this.is_disable_search = false;
      });
    },
    getPositions() {
      this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
        if (!res.error) {
          this.positions = res.data
        }
      });
    },
    deleteUser(id) {
      Swal.fire({
        title: "Bạn chắc chắn muốn xóa?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Xóa", "", "success");
          this.$store.dispatch(DELETE_USER, id).then((res) => {
            if (res.error) {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'danger',
                solid: true
              });
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Xóa',
                variant: 'success',
                solid: true
              });
              this.getList()
            }
          });
        }
      });
    },
    getChiNhanh(item) {
      return item.profile?.branch ? (item.profile?.branch.name_alias ? item.profile?.branch.name_alias : item.profile?.branch.name) : '';
    },
    renderArea(item) {
      if (item.profile.area) {
        return item.profile.area.name;
      }
      return item.profile?.branch?.area ? item.profile?.branch?.area.name : '';
    },
    exportCustomersV2: function () {
      this.is_disable_export = true;
      let is_export_all = true;
      for (const key in this.query) {
        if (key !== 'positions' && this.query[key] !== '') is_export_all = false;
      }
      const HEADER_ROW_EXPORT_CUSTOMER = [
        {
          key: 'name',
          value: 'Họ và tên',
        },
        {
          key: 'id',
          value: 'Mã nhân sự',
        },
        {
          key: 'parent_id',
          value: 'Mã cấp trên',
        },
        {
          key: 'parent_name',
          value: 'Cấp trên',
        },
        {
          key: 'status',
          value: 'Trạng thái',
        },
        {
          key: 'account_type',
          value: 'Vị trí',
        },
        {
          key: 'positions',
          value: 'Kiêm nghiệm',
        },
        {
          key: 'start_to_work',
          value: 'Ngày bắt đầu',
        },
        {
          key: 'ly_do_nghi_viec',
          value: 'Lý do nghỉ việc',
        },
        {
          key: 'thoi_gian_nghi_viec',
          value: 'Ngày thôi việc',
        },
        {
          key: 'address',
          value: 'Quê quán',
        },
        {
          key: 'phone',
          value: 'Số điện thoại',
        },
        {
          key: 'birthday',
          value: 'Ngày sinh',
        },
        {
          key: 'gender',
          value: 'Giới tính',
        },
        {
          key: 'chi_nhanh_id',
          value: 'Mã văn phòng',
        },
        {
          key: 'branch_name',
          value: 'Văn phòng',
        },
        {
          key: 'area_name',
          value: 'Khu vực',
        },
        {
          key: 'quyen_phong_van',
          value: 'Quyền được phỏng vấn',
        },
        {
          key: 'owner_name',
          value: 'Chủ tài khoản',
        },
        {
          key: 'ngan_hang',
          value: 'Tên ngân hàng',
        },
        {
          key: 'stk',
          value: 'Số tài khoản',
        },
        {
          key: 'province',
          value: 'Tỉnh',
        },
        {
          key: 'chi_nhanh_ngan_hang',
          value: 'Chi nhánh',
        },
        {
          key: 'business_level_name',
          value: 'Cấp bậc',
        },
        {
          key: 'salary_mechanism',
          value: 'Cơ chế lương',
        },
        {
          key: 'email',
          value: 'Email',
        },
        {
          key: 'department',
          value: 'Phòng ban',
        },
      ]
      this.$store.dispatch(EXPORT_CUSTOMERS_V2, {
        ...this.query,
        position: this.query.positions,
        is_staff: 1
      }).then((data) => {
        exportExcel(HEADER_ROW_EXPORT_CUSTOMER, data.data, "Danh sách nhân sự.xlsx", false)
      }).finally(() => {
        this.is_disable_export = false;
      });
      // if (is_export_all) {
      //   this.$store.dispatch(EXPORT_STAFF, {
      //     ...this.query,
      //     is_staff: 1
      //   }).finally(() => {
      //     this.is_disable_export = false;
      //   });
      // } else {
      //
      // }
    },
    exportCustomers: function () {
      this.is_disable_export = true;
      let is_export_all = true;
      for (const key in this.query) {
        if (key !== 'positions' && this.query[key] !== '') is_export_all = false;
      }
      if (is_export_all) {
        this.$store.dispatch(EXPORT_STAFF, {
          ...this.query,
          is_staff: 1
        }).finally(() => {
          this.is_disable_export = false;
        });
      } else {
        const HEADER_ROW_EXPORT_CUSTOMER = [
          {
            key: 'name',
            value: 'Họ và tên',
          },
          {
            key: 'id',
            value: 'Mã nhân sự',
          },
          {
            key: 'parent_id',
            value: 'Mã cấp trên',
          },
          {
            key: 'parent_name',
            value: 'Cấp trên',
          },
          {
            key: 'status',
            value: 'Trạng thái',
          },
          {
            key: 'vi_tri',
            value: 'Vị trí',
          },
          {
            key: 'positions',
            value: 'Kiêm nghiệm',
          },
          {
            key: 'start_to_work',
            value: 'Ngày bắt đầu',
          },
          {
            key: 'ly_do_nghi_viec',
            value: 'Lý do nghỉ việc',
          },
          {
            key: 'thoi_gian_nghi_viec',
            value: 'Ngày thôi việc',
          },
          {
            key: 'address',
            value: 'Quê quán',
          },
          {
            key: 'phone',
            value: 'Số điện thoại',
          },
          {
            key: 'birthday',
            value: 'Ngày sinh',
          },
          {
            key: 'gender',
            value: 'Giới tính',
          },
          {
            key: 'chi_nhanh_id',
            value: 'Mã văn phòng',
          },
          {
            key: 'branch_name',
            value: 'Văn phòng',
          },
          {
            key: 'area_name',
            value: 'Khu vực',
          },
          {
            key: 'quyen_phong_van',
            value: 'Quyền được phỏng vấn',
          },
          {
            key: 'owner_name',
            value: 'Chủ tài khoản',
          },
          {
            key: 'ngan_hang',
            value: 'Tên ngân hàng',
          },
          {
            key: 'stk',
            value: 'Số tài khoản',
          },
          {
            key: 'province',
            value: 'Tỉnh',
          },
          {
            key: 'chi_nhanh',
            value: 'Chi nhánh',
          },
          {
            key: 'business_level_name',
            value: 'Cấp bậc',
          },
          {
            key: 'salary_mechanism',
            value: 'Cơ chế lương',
          },
          {
            key: 'email',
            value: 'Email',
          },
          {
            key: 'department',
            value: 'Phòng ban',
          },
          {
            key: 'is_collaborators',
            value: 'Cộng tác viên',
          },
        ]
        this.$store.dispatch(EXPORT_CUSTOMERS, {
          ...this.query,
          position: this.query.positions,
          is_staff: 1
        }).then((data) => {
          exportExcel(HEADER_ROW_EXPORT_CUSTOMER, data.data, "Danh sách nhân sự.xlsx", false)
        }).finally(() => {
          this.is_disable_export = false;
        });
      }
    },
    exportCustomerExample: function () {
      this.is_disable_export_example = true;
      this.$store.dispatch(EXPORT_EMPLOYEE_EXAMPLE, {}).then(() => {
        this.is_disable_export_example = false;
      });
    },
    indentityVerify: function (user) {
      if (user.profile.status_verify === 2) {
        this.showModal = true;
        this.userIdentity = user;
      }
    },
    onSubmit() {
      let formData = new FormData();
      formData.append('dataImport', this.dataImport);
      this.is_loading_import = true;
      this.show_process_loading = true;
      this.$store.dispatch(IMPORT_EMPLOYEE, formData).then((res) => {
        this.is_loading_import = false
        if (!res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Import',
            variant: 'success',
            solid: true
          });
        } else {
          this.$bvToast.toast(res.message, {
            title: 'Import',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((error) => {
        if (error.data.error) {
          this.$bvToast.toast(error.data.data.message_validate_form[0], {
            title: 'Import',
            variant: 'danger',
            solid: true
          });
        }
        this.is_loading_import = false
      });
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },

    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.dataImport = newFile.file;
        this.isAble = false;
      }
      if (newFile && oldFile) {
        // update
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    removeFile: function () {
      this.files.pop();
      this.isAble = true;
    },

  }
}
</script>
<style>
.fa-minus-circle:hover {
  color: red !important;

}

.input-group i {
  font-size: 0.9rem;
}

/*Css multiple select*/
.mx-datepicker {
  width: 100% !important;
}

.dialog-active {
  width: 50%;
  margin: 0 auto;
}

.el-dialog__wrapper {
  overflow: hidden;
}

@media only screen and (max-width: 640px) {
  .dialog-active {
    width: 100%;
    margin: 0 auto;
  }

  .el-dialog {
    width: 95%;
  }
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
